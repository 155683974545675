* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.no-border {
  border: 0;
}

.swal2-title {
  padding: 0px !important;
}

.swal2-html-container {
  margin: 0.6em 1.6em 0.3em !important;
}

.swal2-actions {
  margin: 10px !important;
}
